<template>
  <div class="p-5 h-4/5 lg:h-full">
    <!-- Selected Image Display with Fade & Scale Effect -->
      <div v-if="!showGrid" class="w-full h-full flex justify-center mb-5 relative">
        <img :src="selectedImage" class="w-full object-fill border-2 border-[#2C5BA1] rounded-lg shadow-lg ">

        <!-- Previous Button -->
        <button @click="showPrevious"
                class="absolute -left-4 top-1/2 transform -translate-y-1/2 rounded-full bg-black opacity-80 text-white py-3 px-5 shadow-md transition duration-300 hover:opacity-100">
          <i class="fas fa-chevron-left"></i>
        </button>

        <!-- Next Button -->
        <button @click="showNext"
                class="absolute -right-4 top-1/2 transform -translate-y-1/2 rounded-full bg-black opacity-80 text-white py-3 px-5 shadow-md transition duration-300 hover:opacity-100">
          <i class="fas fa-chevron-right"></i>
        </button>

        <!-- Toggle Button (Positioned at Bottom-Center of Image) -->
        <button @click="toggleGrid"
                class="absolute bottom-4 left-1/2 rounded-full transform -translate-x-1/2 bg-black opacity-80 text-white px-2 py-2 shadow-md transition duration-300 hover:opacity-100">
                <img src="/icons/grid.svg" class="Grid Call Tunisia Go Travel" />

        </button>
      </div>
    <!-- Image Selection Grid with Fade & Slide Effect -->
    <transition name="grid-fade-in">
      <div v-if="showGrid" class="grid grid-cols-2 lg:grid-cols-4 gap-4 h-[calc(70vh-8rem)] lg:h-[calc(85vh-8rem)] overflow-y-auto">
        <img v-for="(image, index) in images" :key="index"
             :src="image"
             :class="{'border-4 border-[#2C5BA1]': image === selectedImage}"
             class="cursor-pointer w-40 h-40 object-cover rounded-md shadow-md hover:opacity-75 transition-transform transform scale-95 hover:scale-105 duration-300 ease-in-out"
             @click="selectImage(image)"
             v-motion="{ initial: { opacity: 0, scale: 0.8 }, visible: { opacity: 1, scale: 1 } }"
             transition="{ delay: index * 0.2, duration: 0.8 }">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedImage: this.images.length > 0 ? this.images[0] : '', // Default to first image
      showGrid: false, // Initially hide the grid
    };
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
  },
  methods: {
    toggleGrid() {
      this.showGrid = !this.showGrid;
    },
    selectImage(image) {
      this.selectedImage = image;
      this.showGrid = false; // Hide the grid when an image is selected
    },
    showNext() {
      const currentIndex = this.images.indexOf(this.selectedImage);
      const nextIndex = (currentIndex + 1) % this.images.length;
      this.selectedImage = this.images[nextIndex];
    },
    showPrevious() {
      const currentIndex = this.images.indexOf(this.selectedImage);
      const prevIndex = (currentIndex - 1 + this.images.length) % this.images.length;
      this.selectedImage = this.images[prevIndex];
    },
    handleKeydown(event) {
      if (!this.showGrid) {
        if (event.key === "ArrowRight") {
          this.showNext();
        } else if (event.key === "ArrowLeft") {
          this.showPrevious();
        }
      }
    }
  }
};
</script>
