<template>
    <DetailsMedicalView v-if="medical" :element="medical" :parentUrl="'/aesthetic'" />
    <p v-else class="text-center text-gray-500">Aucun médical trouvé.</p>
  </template>
  
  <script>
  import DetailsMedicalView from "../partial/DetailsMedicalView.vue";
  import { mapActions } from "vuex";
  
  export default {
    components: {
      DetailsMedicalView,
    },
    computed: {
      medical() {
        console.log("my medicals ",this.myMedicals);
        return this.myMedicals.find((x) => x.slug === "la-medecine-esthetique") || null;
      },
    },
    data(){
        return {
           myMedicals:[]
        }
    },
    methods: {
      ...mapActions(["fetchMedicals"]),
      async loadMedicals() {
        try {
          console.log("Fetching medicals...");
          this.myMedicals= await this.fetchMedicals();  
          console.log("my medicals ",this.myMedicals);      
        } catch (error) {
          console.error("Error loading medicals: ", error);
        }
      },
    },
    async created() {
      this.$store.dispatch("setLoading", true);
      try {
        await this.loadMedicals();
      } catch (error) {
        console.error("Error:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },
    watch: {
      medicals(newVal) {
        console.log("Updated medicals:", newVal);
      },
    },
  };
  </script>
  