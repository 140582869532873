<template>
    <DetailsArtisanats v-if="artisanat" :element="artisanat" :parentUrl="'/pottery'" />
    <p v-else class="text-center text-gray-500">Aucun artisanat trouvé.</p>
  </template>
  
  <script>
  import DetailsArtisanats from "../partial/DetailsArtisanats.vue";
  import { mapActions, mapState } from "vuex";
  
  export default {
    components: {
      DetailsArtisanats,
    },
    computed: {
      ...mapState(["artisanats"]), // Map artisanats from Vuex store
      artisanat() {
        return this.artisanats?.find((x) => x.slug === "poterie") || null;
      },
    },
    methods: {
      ...mapActions(["fetchArtisanats"]),
      async loadArtisanats() {
        try {
          await this.fetchArtisanats();
        } catch (error) {
          console.error("Error loading artisanats: ", error);
        }
      },
    },
    async created() {
      this.$store.dispatch("setLoading", true);
      try {
        await this.loadArtisanats();
      } catch (error) {
        console.log("Error:", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },
  };
  </script>
  