<template>
    <div
        v-if="element?.cover"
      class="relative bg-no-repeat bg-center bg-cover grow flex flex-col-reverse pb-6 lg:pb-10 h-[10rem] lg:h-[15rem]"
      :style="{
        backgroundImage: `url(${element?.cover})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div class="absolute inset-0 bg-[#2C5BA1] bg-opacity-40"></div>
      <div
        class="absolute inset-0 flex items-center justify-center text-white text-lg text-center lg:text-3xl font-bold"
      >

      </div>
      <div
      class="absolute inset-0 flex flex-col items-center justify-center text-white text-3xl font-bold"
    >
        <h1 v-if="currentLanguage === 'fr'">{{ element?.title }}</h1>
        <h1 v-if="currentLanguage === 'ar'">{{ element?.title_ar }}</h1>
        <h1 v-if="currentLanguage === 'en'">{{ element?.title_en }}</h1>
      <div class="text-xs lg:text-base font-semibold text-white mt-4">
        <p class="flex flex-row gap-2">
          <a class="hover:underline" :href="`/`">{{ $t("home") }}</a>
          <span v-if="currentLanguage === 'ar'">/</span>
          <span v-if="currentLanguage !== 'ar'">\</span>
          <a class="hover:underline" :href="parentUrl">
            <span v-if="currentLanguage === 'fr'">{{ element?.title }}</span>
            <span v-if="currentLanguage === 'ar'">{{ element?.title_ar }}</span>
            <span v-if="currentLanguage === 'en'">{{ element?.title_en }}</span>
          </a>
        </p>
      </div>
    </div>
    </div>
    <div class="container p-3.5 sm:px-6 md:px-8 lg:px-16 xl:px-16 mx-auto my-2"         v-if="element"
    >
      <div class="container p-3.5 sm:px-6 md:px-8 lg:px-16 xl:px-16 mx-auto my-2">
        <h1
          class="text-center font-bold text-2xl text-blue my-4"
          v-if="currentLanguage === 'fr'"
        >
          {{ element?.title }}
        </h1>
        <h1
          class="text-center font-bold text-2xl text-blue my-4"
          v-if="currentLanguage === 'ar'"
        >
          {{ element?.title_ar }}
        </h1>
        <h1
          class="text-center font-bold text-2xl text-blue my-4"
          v-if="currentLanguage === 'en'"
        >
          {{ element?.title_en }}
        </h1>
        <div class="grid grid-cols-1 gap-2">
          <div>
            <h2 class="font-bold text-xl mb-3">{{ $t("description") }}</h2>
            <template v-if="currentLanguage === 'fr'">
              <div v-html="element?.description"></div>
            </template>
            <template v-if="currentLanguage === 'ar'">
              <div v-html="element?.description_ar"></div>
            </template>
            <template v-if="currentLanguage === 'en'">
              <div v-html="element?.description_en"></div>
            </template>
          </div>
        </div>
        <div v-if="element.medical_authorities" class="my-8">
            <DynamicMapPopUp
        :elements="element.medical_authorities"
        :icon="'/mapMarkers/medical-icon.png'"
      />
        </div>

        <div class="grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4" v-if="element.medical_authorities">
            <div
              v-for="(medical, index) in element.medical_authorities"
              :key="index"
              class="col-span-1"
            >
              <MedicalCard :medical="medical" />
            </div>
        </div>
        <h1 class="text-xl text-center font-bold mb-4">
          {{ $t("latest_articles") }}
        </h1>
        <div
          class="container p-3.5 sm:px-4 md:px-8 lg:px-16 xl:px-16 mx-auto my-2"
        >
          <ArticleList :articles="articles" />
        </div>
        <div
          class="container p-3.5 lg:px-16 xl:px-16 mx-auto my-2"
          v-if="
            this.imageListBanner1.length !== 0 ||
            this.imageListBanner2.length !== 0
          "
        >
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
            <BannerPub
              class="block sm:hidden"
              :images="[...this.imageListBanner1, ...this.imageListBanner2]"
            />
            <BannerPub
              class="hidden sm:block"
              :images="
                this.imageListBanner1.length !== 0
                  ? this.imageListBanner1
                  : this.imageListBanner3
              "
            />
            <BannerPub
              class="hidden sm:block"
              :images="
                this.imageListBanner2.length !== 0
                  ? this.imageListBanner2
                  : this.imageListBanner3
              "
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  import { mapActions } from "vuex";
  import BannerPub from "../partial/BannerPub.vue";
  import ArticleList from "../partial/ArticleList.vue";
  import MedicalCard from "./MedicalCard.vue";
  import DynamicMapPopUp from "./DynamicMapPopUp";

  export default {
    components: {
      BannerPub,
      ArticleList,
      MedicalCard,
      DynamicMapPopUp
    },
    props: {
      element: {
        type: Object,
        required: true,
      },
      parentUrl: String
    },
    data() {
      return {
        currentLanguage: this.$i18n.locale,
        imageListBanner1: [],
        imageListBanner2: [],
        imageListBanner3: [],
        articles: [],
      };
    },
    methods: {
      ...mapActions(["fetchBanners", "fetchArticles"]),
      addCanonicalLink() {
        const currentUrl = window.location.href; // Get the current URL
        const currentLanguage = this.currentLanguage; // Fetch the current language
        let canonicalLink = document.querySelector("link[rel='canonical']");
  
        // Modify the URL based on the language
        const url = new URL(currentUrl);
        const pathSegments = url.pathname.split("/").filter(Boolean); // Remove empty segments
  
        if (currentLanguage === "ar" || currentLanguage === "en") {
          // Add language prefix if not already present
          if (pathSegments[0] !== currentLanguage) {
            pathSegments.unshift(currentLanguage);
          }
        } else if (
          (currentLanguage === "fr" && pathSegments[0] === "ar") ||
          pathSegments[0] === "en"
        ) {
          // Remove language prefix if "fr" (default)
          pathSegments.shift();
        }
  
        url.pathname = "/" + pathSegments.join("/"); // Construct the new path
        const canonicalUrl = url.toString(); // Get the full URL as a string
  
        if (canonicalLink) {
          // Update the existing canonical link
          canonicalLink.setAttribute("href", canonicalUrl);
        } else {
          // Create a new canonical link
          canonicalLink = document.createElement("link");
          canonicalLink.setAttribute("rel", "canonical");
          canonicalLink.setAttribute("href", canonicalUrl);
          document.head.appendChild(canonicalLink);
        }
      },
      async loadBannerData() {
        try {
          const data = await this.fetchBanners();
          let arrayBanners = Object.values(data);
          this.imageListBanner1 = arrayBanners[0];
          this.imageListBanner2 = arrayBanners[1];
          this.imageListBanner3 = arrayBanners[2];
        } catch (error) {
          console.error("Error loading banners: ", error);
        }
      },
      async loadArticles() {
        try {
          const articlesList = await this.fetchArticles();
          this.articles = articlesList;
        } catch (error) {
          console.error("Error loading articlesList: ", error);
        }
      },
      updateMetaTags(hotel) {
        if (this.currentLanguage === "ar") {
          document.title = hotel.seo.meta_title_ar;
          let metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              hotel.seo.meta_description_ar || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content =
              hotel.seo.meta_description_ar ||
              "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(metaDescription);
          }
  
          let metaKeywords = document.querySelector("meta[name='keywords']");
          if (metaKeywords) {
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords_ar ||
                "restaurant, Tunisie, cuisine, gastronomie"
            );
          } else {
            metaKeywords = document.createElement("meta");
            metaKeywords.setAttribute("name", "keywords");
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords_ar || "Hotel, Tunisie, cuisine, gastronomie"
            );
            document.head.appendChild(metaKeywords);
          }
  
          let ogTitle = document.querySelector('meta[property="og:title"]');
          if (ogTitle) {
            ogTitle.setAttribute(
              "content",
              hotel.seo.meta_title_ar || hotel.name_ar
            );
          } else {
            ogTitle = document.createElement("meta");
            ogTitle.property = "og:title";
            ogTitle.content = hotel.seo.meta_title_ar || hotel.name_ar;
            document.head.appendChild(ogTitle);
          }
  
          let ogDescription = document.querySelector(
            'meta[property="og:description"]'
          );
          if (ogDescription) {
            ogDescription.setAttribute(
              "content",
              hotel.seo.meta_description_ar || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            ogDescription = document.createElement("meta");
            ogDescription.property = "og:description";
            ogDescription.content =
              hotel.seo.meta_description_ar ||
              "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(ogDescription);
          }
  
          let ogImage = document.querySelector('meta[property="og:image"]');
          if (ogImage) {
            ogImage.setAttribute("content", hotel.cover);
          } else {
            ogImage = document.createElement("meta");
            ogImage.property = "og:image";
            ogImage.content = hotel.cover;
            document.head.appendChild(ogImage);
          }
        }
        if (this.currentLanguage === "fr") {
          document.title = hotel.seo.meta_title;
          let metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              hotel.seo.meta_description || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content =
              hotel.seo.meta_description || "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(metaDescription);
          }
  
          let metaKeywords = document.querySelector("meta[name='keywords']");
          if (metaKeywords) {
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords || "Hotel, Tunisie, cuisine, gastronomie"
            );
          } else {
            metaKeywords = document.createElement("meta");
            metaKeywords.setAttribute("name", "keywords");
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords || "Hotel, Tunisie, cuisine, gastronomie"
            );
            document.head.appendChild(metaKeywords);
          }
  
          let ogTitle = document.querySelector('meta[property="og:title"]');
          if (ogTitle) {
            ogTitle.setAttribute("content", hotel.seo.meta_title || hotel.name);
          } else {
            ogTitle = document.createElement("meta");
            ogTitle.property = "og:title";
            ogTitle.content = hotel.seo.meta_title || hotel.name;
            document.head.appendChild(ogTitle);
          }
  
          let ogDescription = document.querySelector(
            'meta[property="og:description"]'
          );
          if (ogDescription) {
            ogDescription.setAttribute(
              "content",
              hotel.seo.meta_description || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            ogDescription = document.createElement("meta");
            ogDescription.property = "og:description";
            ogDescription.content =
              hotel.seo.meta_description || "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(ogDescription);
          }
  
          let ogImage = document.querySelector('meta[property="og:image"]');
          if (ogImage) {
            ogImage.setAttribute("content", hotel.cover);
          } else {
            ogImage = document.createElement("meta");
            ogImage.property = "og:image";
            ogImage.content = hotel.cover;
            document.head.appendChild(ogImage);
          }
        }
        if (this.currentLanguage === "en") {
          document.title = hotel.seo.meta_title_en;
          let metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescription) {
            metaDescription.setAttribute(
              "content",
              hotel.seo.meta_description_en || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            metaDescription = document.createElement("meta");
            metaDescription.name = "description";
            metaDescription.content =
              hotel.seo.meta_description_en ||
              "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(metaDescription);
          }
  
          let metaKeywords = document.querySelector("meta[name='keywords']");
          if (metaKeywords) {
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords_en || "Hotel, Tunisie, cuisine, gastronomie"
            );
          } else {
            metaKeywords = document.createElement("meta");
            metaKeywords.setAttribute("name", "keywords");
            metaKeywords.setAttribute(
              "content",
              hotel.seo.meta_keywords_en || "Hotel, Tunisie, cuisine, gastronomie"
            );
            document.head.appendChild(metaKeywords);
          }
  
          let ogTitle = document.querySelector('meta[property="og:title"]');
          if (ogTitle) {
            ogTitle.setAttribute(
              "content",
              hotel.seo.meta_title_en || hotel.name_en
            );
          } else {
            ogTitle = document.createElement("meta");
            ogTitle.property = "og:title";
            ogTitle.content = hotel.seo.meta_title_en || hotel.name_en;
            document.head.appendChild(ogTitle);
          }
  
          let ogDescription = document.querySelector(
            'meta[property="og:description"]'
          );
          if (ogDescription) {
            ogDescription.setAttribute(
              "content",
              hotel.seo.meta_description_en || "Découvrez notre hôtel en Tunisie."
            );
          } else {
            ogDescription = document.createElement("meta");
            ogDescription.property = "og:description";
            ogDescription.content =
              hotel.seo.meta_description || "Découvrez notre hôtel en Tunisie.";
            document.head.appendChild(ogDescription);
          }
  
          let ogImage = document.querySelector('meta[property="og:image"]');
          if (ogImage) {
            ogImage.setAttribute("content", hotel.cover);
          } else {
            ogImage = document.createElement("meta");
            ogImage.property = "og:image";
            ogImage.content = hotel.cover;
            document.head.appendChild(ogImage);
          }
        }
      },
    },
    watch: {
      "$i18n.locale"(newLocale) {
        this.currentLanguage = newLocale;
        this.addCanonicalLink();
        this.updateMetaTags(this.$props.element);
      },
    },
    async created() {
      this.updateMetaTags(this.$props.element);
      this.$store.dispatch("setLoading", true);
      try {
        await Promise.all([this.loadBannerData(), this.loadArticles()]);
      } catch (error) {
        console.log("error ", error);
      } finally {
        this.$store.dispatch("setLoading", false);
      }
    },
  };
  </script>
  