<template>
  <div>
    <!-- Background Section -->
    <div
      class="relative bg-no-repeat bg-center bg-cover grow flex flex-col-reverse pb-6 lg:pb-10 h-[10rem] lg:h-[15rem]"
      :style="{
        backgroundImage: `url('./images/car.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }"
    >
      <div class="absolute inset-0 bg-[#2C5BA1] bg-opacity-40"></div>
      <div
        class="absolute inset-0 flex flex-col items-center justify-center text-white text-3xl font-bold"
      >
        <h1>{{ $t("voitureLocation") }}</h1>
        <div class="text-xs lg:text-base font-semibold text-white mt-4">
          <p class="flex flex-row gap-2">
            <a class="hover:underline" :href="`/`">{{ $t("home") }}</a>
            <span v-if="currentLanguage === 'ar'">/</span>
            <span v-if="currentLanguage !== 'ar'">\</span>
            <a class="hover:underline" :href="`/vehicules`">{{
              $t("voitureLocation")
            }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="container p-3.5 sm:px-4 md:px-8 lg:px-16 xl:px-16 mx-auto my-2">
      <div class="grid grid-cols-1 lg:grid-cols-4">
        <div class="col-span-1 hidden lg:block">
          <div class="container p-3.5 my-4">
            <div class="shadow-md my-16 bg-[#F0F6FF] rounded-2xl">
              <div class="rounded-t-2xl  px-2 py-2">
                <span class="text-xl text-blue ltr:text-left font-bold">
                  {{ $t("search") }}
                </span>
                <div class="mb-4">
                  <label
                    for="fromDate"
                    class="block text-sm font-medium text-gray-700"
                    >{{ $t("dateDebut") }}</label
                  >
                  <input
                    type="date"
                    id="fromDate"
                    v-model="selectedDate.from"
                    :min="nextDate"
                    class="w-full p-2 border border-gray-300 rounded-lg"
                    @change="filterData"
                  />
                </div>
                <div class="mb-4">
                  <label
                    for="toDate"
                    class="block text-sm font-medium text-gray-700"
                    >{{ $t("arrivalDate") }}</label
                  >
                  <input
                    type="date"
                    id="toDate"
                    :min="nextDate"
                    v-model="selectedDate.to"
                    class="w-full p-2 border border-gray-300 rounded-lg"
                    @change="filterData"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-1 lg:col-span-3">
          <div class="mt-6 flex justify-center">
            <button
            aria-label="Véhicule Tab"          
              class="px-4 py-2 mx-2 text-white font-semibold rounded-lg flex items-center gap-2"
              :class="{
                'bg-blue': activeTab === 'vehicules',
                'bg-orange': activeTab !== 'vehicules',
              }"
              @click="activeTab = 'vehicules'"
            >
              <i class="fas fa-car"></i> {{ $t("cars") }}
            </button>
            <button
            aria-label="Bus Tab"          
              class="px-4 py-2 mx-2 text-white font-semibold rounded-lg flex items-center gap-2"
              :class="{
                'bg-blue': activeTab === 'bus',
                'bg-orange': activeTab !== 'bus',
              }"
              @click="activeTab = 'bus'"
            >
              <i class="fas fa-bus"></i> {{ $t("bus") }}
            </button>
          </div>

          <!-- Vehicles Section -->
          <div v-if="activeTab === 'vehicules'">
            <div class="w-full flex block lg:hidden justify-center mt-2">
              <div class="relative w-96">
                <!-- Card Toggle Button -->
                <button
                aria-label="Card filter dates"          
                  @click="isCardOpen = !isCardOpen"
                  class="bg-orange text-white px-4 py-2 rounded-t-lg w-full text-left"
                >
                  {{ isCardOpen ? "Fermer" : "Ouvrir" }} le filtre de date
                </button>

                <!-- Card Content -->
                <div
                  v-if="isCardOpen"
                  class="bg-white shadow-lg rounded-b-lg p-6 border border-gray-300"
                >
                  <div class="mb-4">
                    <label
                      for="fromDate"
                      class="block text-sm font-medium text-gray-700"
                      >Date de début</label
                    >
                    <input
                      type="date"
                      id="fromDate"
                      :min="nextDate"
                      v-model="selectedDate.from"
                      class="w-full p-2 border border-gray-300 rounded-lg"
                      @change="filterData"
                    />
                  </div>
                  <div class="mb-4">
                    <label
                      for="toDate"
                      class="block text-sm font-medium text-gray-700"
                      >Date de fin</label
                    >
                    <input
                      type="date"
                      id="toDate"
                      :min="nextDate"
                      v-model="selectedDate.to"
                      class="w-full p-2 border border-gray-300 rounded-lg"
                      @change="filterData"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6"
            >
              <div
                v-for="vehicle in vehicles"
                :key="vehicle.id"
                class="border rounded-2xl p-4 overflow-hidden shadow-lg bg-blue-clair"
                v-motion="{
                  initial: { opacity: 0, scale: 0.8 },
                  visible: { opacity: 1, scale: 1 },
                }"
                transition="{ delay: index * 0.2, duration: 0.8 }"
              >
                <div class="flex justify-between">
                  <span class="text-blue font-bold"
                    >{{ vehicle.price[0].price }} <sup>DT</sup></span
                  >
                  <button
                    class="w-32 bg-orange text-white py-2 px-2 rounded-lg hover:bg-green-700 transition"
                    @click="openModal(vehicle.id, 'vehicle')"
                  >
                    {{ $t("reservation") }}
                  </button>
                </div>
                <div class="flex flex-col justify-center items-center h-full">
                  <div class="text-center font-semibold text-orange my-1">{{ vehicle.registration_number }}</div>
                  <img
                    class="mr-4 rounded-lg"
                    :src="vehicle.photo"
                    :alt="vehicle.title"
                    style="mix-blend-mode: multiply; background: transparent"
                  />
                  <h3
                    class="text-md font-semibold mt-2 truncate"
                    :title="vehicle.title"
                  >
                    {{ vehicle.marque }} - {{ vehicle.model }}
                  </h3>
                  <span class="text-blue text-center font-semibold">{{ vehicle.kilometers  }}Km</span>
                </div>
                <div class="flex justify-between mt-3">
                  <span>{{ vehicle.kilometers }} Km |</span>
                  <span>{{ vehicle.registration_number }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Bus Section -->
          <div v-if="activeTab === 'bus'">
            <div class="w-full flex block lg:hidden justify-center mt-2">
              <div class="relative w-96">
                <!-- Card Toggle Button -->
                <button
                  aria-label="Filter dates Bus"          
                  @click="isCardOpen = !isCardOpen"
                  class="bg-orange text-white px-4 py-2 rounded-t-lg w-full text-left"
                >
                  {{ isCardOpen ? "Fermer" : "Ouvrir" }} le filtre de date
                </button>

                <!-- Card Content -->
                <div
                  v-if="isCardOpen"
                  class="bg-white shadow-lg rounded-b-lg p-6 border border-gray-300"
                >
                  <div class="mb-4">
                    <label
                      for="fromDate"
                      class="block text-sm font-medium text-gray-700"
                      >Date de début</label
                    >
                    <input
                      type="date"
                      id="fromDate"
                      :min="nextDate"
                      v-model="selectedDate.from"
                      class="w-full p-2 border border-gray-300 rounded-lg"
                      @change="filterData"
                    />
                  </div>
                  <div class="mb-4">
                    <label
                      for="toDate"
                      class="block text-sm font-medium text-gray-700"
                      >Date de fin</label
                    >
                    <input
                      type="date"
                      id="toDate"
                      :min="nextDate"
                      v-model="selectedDate.to"
                      class="w-full p-2 border border-gray-300 rounded-lg"
                      @change="filterData"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6"
            >
              <div
                v-for="bus in buses"
                :key="bus.id"
                class="border rounded-2xl p-4 overflow-hidden shadow-lg bg-blue-clair"
                v-motion="{
                  initial: { opacity: 0, scale: 0.8 },
                  visible: { opacity: 1, scale: 1 },
                }"
                transition="{ delay: index * 0.2, duration: 0.8 }"
              >
                <div class="flex justify-between">
                  <span class="text-blue font-bold"
                    >{{ bus.price[0].price }} <sup>DT</sup></span
                  >
                  <button
                    class="w-32 bg-orange text-white py-2 px-2 rounded-lg hover:bg-green-700 transition"
                    @click="openModal(bus.id, 'bus')"
                  >
                    {{ $t("reservation") }}
                  </button>
                </div>
                <div class="flex flex-col justify-center items-center h-full">
                  <div class="text-center font-semibold text-orange">{{ bus.registration_number }}</div>

                  <img class="mr-4" :src="bus.photo" :alt="bus.title" />
                  <h3
                    class="text-md font-semibold mt-2 truncate"
                    :title="bus.title"
                  >
                    {{ bus.marque }} - {{ bus.model }}
                  </h3>
                  <div class="text-center font-semibold text-blue">{{ bus.kilometers }} Km</div>
                </div>
                <div class="flex justify-between mt-3">
                  <span>{{ bus.kilometers }} Km |</span>
                  <span>{{ bus.registration_number }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      v-if="isModalVisible"
      @click.self="isModalVisible = false"
      class="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-[10000000]"
    >
      <div class="bg-white rounded-lg p-6 w-96">
        <h2 class="text-xl font-bold mb-4">{{ $t("reservationDetails") }}</h2>
        <form @submit.prevent="submitReservation">
          <div class="mb-4">
            <label for="fromDate" class="block text-sm font-medium">{{
              $t("from")
            }}</label>
            <input
              type="date"
              id="fromDate"
              v-model="reservationData.from"
              required
              class="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div class="mb-4">
            <label for="toDate" class="block text-sm font-medium">{{
              $t("to")
            }}</label>
            <input
              type="date"
              id="toDate"
              :min="nextDate"
              required
              v-model="reservationData.to"
              class="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <!-- New Ramassage Field -->
          <div class="mb-4">
            <label for="ramassage" class="block text-sm font-medium">{{
              $t("ramassage")
            }}</label>
            <input
              type="text"
              id="ramassage"
              required
              v-model="reservationData.ramassage"
              class="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <!-- New Distribution Field -->
          <div class="mb-4">
            <label for="distribution" class="block text-sm font-medium">{{
              $t("distribution")
            }}</label>
            <input
              type="text"
              id="distribution"
              required
              v-model="reservationData.distribution"
              class="w-full p-2 border border-gray-300 rounded-lg"
            />
          </div>
          <div class="mb-4">
            <button
              type="submit"
              class="w-full bg-blue text-white py-2 rounded-lg hover:bg-blue-700"
            >
              {{ $t("confirmReservation") }}
            </button>
          </div>
        </form>
        <button
          @click="closeModal"
          class="absolute top-0 right-0 m-2 text-gray-600"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { useToast } from "vue-toastification";

export default {
  data() {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);
    return {
      activeTab: "vehicules",
      vehicles: [],
      isCardOpen: false,
      buses: [],
      startDate: "",
      selectedDate: {
        to: "",
        from: "",
      },
      isModalVisible: false,
      endDate: "",
      price: 0,
      diffDays: 0,
      total: 0,
      reservationData: {
        from: "",
        to: "",
        vehicleId: null,
        busId: null,
        ramassage: "",
        distribution: "",
      },
      currentDate: new Date().toISOString().split("T")[0],
      nextDate: nextDate.toISOString().split("T")[0],
    };
  },
  async created() {
    this.setWeekDates();
    this.$store.dispatch("setLoading", true);
    try {
      await this.fetchVehicles();
      await this.fetchBuses();
    } catch (error) {
      console.log("error ", error);
    } finally {
      this.$store.dispatch("setLoading", false);
    }
  },
  methods: {
    setWeekDates() {
      const startOfWeek = moment().startOf("week").format("YYYY-MM-DD");
      const endOfWeek = moment().endOf("week").format("YYYY-MM-DD");
      this.startDate = startOfWeek;
      this.endDate = endOfWeek;
      this.reservationData.to = new Date(this.selectedDate.to);
      this.reservationData.from = new Date(this.selectedDate.from);
    },
    async fetchVehicles() {
      try {
        const response = await axios.get(
          `https://backend.tunisiagotravel.com/utilisateur/vehicles?from=${this.startDate}&to=${this.endDate}`
        );
        this.vehicles = response.data.data;
      } catch (error) {
        console.error("Error fetching vehicles:", error);
      }
    },
    closeModal() {
      this.isModalVisible = false; // Close the modal
    },
    async fetchBuses() {
      try {
        const response = await axios.get(
          `https://backend.tunisiagotravel.com/utilisateur/bus?from=${this.startDate}&to=${this.endDate}`
        );
        this.buses = response.data.data;
      } catch (error) {
        console.error("Error fetching buses:", error);
      }
    },
    openModal(id, type) {
      this.reservationData = {
        ...this.reservationData,
        [`${type}Id`]: id,
      };
      this.isModalVisible = true;
    },
    async reserveVehicle(payload, token) {
      try {
        const response = await axios.post(
          `https://backend.tunisiagotravel.com/utilisateur/vehicles/reservations`,
          { ...payload, vehicle_id: this.reservationData.vehicleId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          return { success: true };
        }
      } catch (error) {
        return { success: false, message: error.response?.data?.message };
      }
    },
    filterData() {
      this.startDate = this.selectedDate.to;
      this.endDate = this.selectedDate.from;
      if (this.activeTab === "vehicules") {
        this.fetchVehicles();
      } else {
        this.fetchBuses();
      }
    },
    async reserveBus(payload, token) {
      try {
        const response = await axios.post(
          `https://backend.tunisiagotravel.com/utilisateur/bus/reservations`,
          { ...payload, bus_id: this.reservationData.busId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200 || response.status === 201) {
          return { success: true };
        }
      } catch (error) {
        return { success: false, message: error.response?.data?.message };
      }
    },
    async submitReservation() {
      const token = localStorage.getItem("token");
      const fromDate = new Date(this.reservationData.from);
      const toDate = new Date(this.reservationData.to);

      // Calculate difference in milliseconds and convert to days
      const diffTime = toDate.getTime() - fromDate.getTime();
      this.diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log("my diff days ", this.diffDays);
      if (this.activeTab === "vehicules") {
        this.price = this.vehicles.filter(
          (x) => x.id === this.reservationData.vehicleId
        )[0].price[0].price;
      } else {
        this.price = this.buses.filter(
          (x) => x.id === this.reservationData.busId
        )[0].price[0].price;
      }
      const payload = {
        from: this.reservationData.from,
        to: this.reservationData.to,
        ramassage: this.reservationData.ramassage,
        distribution: this.reservationData.distribution,
        price: this.price,
        total: this.price * this.diffDays,
      };
      if (token) {
        const reservationType = this.reservationData.vehicleId
          ? "vehicle"
          : "bus";

        const result =
          reservationType === "vehicle"
            ? await this.reserveVehicle(payload, token)
            : await this.reserveBus(payload, token);

        const toast = useToast();

        if (result.success) {
          toast.success("Reservation successful!");
          this.closeModal();
        } else {
          toast.error(`Error: ${result.message}`);
        }
      } else {
        const toast = useToast();

        toast.warning(this.$t("veuillezVousConnecter"));
      }
    },
  },
};
</script>

<style scoped>
:root {
  --v-calendar-active-bg-color: #2c5ba1 !important;
  --v-calendar-datepicker-icon-color: #2c5ba1 !important;
}
.v-calendar .input-field input {
  padding-left: 40px !important;
  padding-right: 20px !important;
  font-size: #2c5ba1 !important;
  min-width: inherit !important ;
  height: 40px !important;
  border-radius: 12px !important;
  color: #2c5ba1 !important;
  font-weight: inherit !important;
}
</style>
