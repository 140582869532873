<template>
  <div
    class="container p-3.5 lg:px-16 xl:px-16 mx-auto mt-2 relative"
    v-motion
    :initial="{ opacity: 0, x: -50, scale: 0.9 }"
    :enter="{ opacity: 1, x: 0, scale: 1 }"
    :hover="{ scale: 1.05, rotateY: 10 }"
    transition="{ stiffness: 50, damping: 15 }"
  >
    <!-- Mobile: Single card with auto-scrolling -->
    <div v-if="isMobile" class="swiper-container">
      <swiper
        :spaceBetween="30"
        :navigation="{
          nextEl: '.custom-nextArticle',
          prevEl: '.custom-prevArticle',
        }"
        :autoplay="{ delay: 3000, disableOnInteraction: false }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(article, index) in articles" :key="index">
          <ArticleElement :article="article" />
        </swiper-slide>
        <!-- Navigation buttons -->
        <button class="custom-prevArticle" aria-label="previous article">
          <i class="fas fa-chevron-left"></i>
        </button>
        <button class="custom-nextArticle" aria-label="next article">
          <i class="fas fa-chevron-right"></i>
        </button>
      </swiper>
    </div>

    <!-- Tablet and Desktop: Multiple cards with navigation -->
    <div v-else>
      <swiper
        :spaceBetween="10"
        :breakpoints="{
          '640': { slidesPerView: 2, spaceBetween: 10 }, // Tablet
          '1024': { slidesPerView: 4, spaceBetween: 15 }, // Desktop
        }"
        :navigation="{
          nextEl: '.custom-nextArticle',
          prevEl: '.custom-prevArticle',
        }"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="(article, index) in articles" :key="index">
          <ArticleElement :article="article" />
        </swiper-slide>
        <!-- Navigation buttons (only show if there are more than 4 articles) -->
        <button
          class="custom-prevArticle"
          aria-label="previous article"
          v-if="articles.length >= 5"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="custom-nextArticle"
          aria-label="next article"
          v-if="articles.length >= 5"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </swiper>
    </div>

    <!-- "See All" link -->
    <a
      href="https://tunisiagotravel.com/blog"
      target="_blank"
      class="text-blue underline font-bold text-sm flex justify-center mt-4"
    >
      {{ $t('seeAll') }}
    </a>
  </div>
</template>
  
<script>
import ArticleElement from './ArticleElement.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation'; // Import styles for navigation
import { Navigation, Autoplay } from 'swiper/modules';

export default {
  components: {
    ArticleElement,
    Swiper,
    SwiperSlide,
  },
  props: {
    articles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth <= 600;
    },
  },
  async mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style scoped>
.mySwiper {
  width: 100%;
  padding: 8px;
  height: 21rem;
}

@media (max-width: 1024px) {
  .mySwiper {
    height: 19rem;
  }
}

.custom-prevArticle,
.custom-nextArticle {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #fff; /* White icon color */
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
}

.custom-prevArticle {
  left: 5px;
}

.custom-nextArticle {
  right: 5px;
}

.custom-prevArticle i,
.custom-nextArticle i {
  font-size: 16px;
}

@media (min-width: 1024px) {
  .custom-prevArticle {
    left: 0px;
  }
  .custom-nextArticle {
    right: 0px;
  }
}
</style>