import { createRouter, createWebHistory } from 'vue-router';
import AOS from "aos";
import 'aos/dist/aos.css';
import CuivreView from '@/frontoffice/pages/CuivreView.vue';
import PoterieView from '@/frontoffice/pages/PoterieView.vue';
import TapisView from '@/frontoffice/pages/TapisView.vue';
import VannerieView from '@/frontoffice/pages/VannerieView.vue';
import LeatherView from '@/frontoffice/pages/LeatherView.vue';
import RelaxationView from '@/frontoffice/pages/RelaxationView.vue';
import SanteMedicalView from '@/frontoffice/pages/SanteMedicalView.vue';
import SanteMedecineEsthetique from '@/frontoffice/pages/SanteMedecineEsthetique.vue';
import SalonCoiffureView from '@/frontoffice/pages/SalonCoiffureView.vue';
import CentresRemiseEnforme from '@/frontoffice/pages/CentresRemiseEnforme.vue';
import VehiculesView from '@/frontoffice/pages/VehiculesView.vue';
const FrontLayout = () => import('../frontoffice/layout/FrontLayout.vue');
const HomeView = () => import('../frontoffice/pages/HomeView.vue');
const HotelsView = () => import('../frontoffice/pages/HotelsView.vue');
const DetailsHotelsView = () => import('../frontoffice/pages/DetailsHotelsView.vue');
const DetailsRestaurantsView = () => import('../frontoffice/pages/DetailsRestaurantsView.vue');
const DetailsEventsView = () => import('../frontoffice/pages/DetailsEventsView.vue');
const DetailsMusseView = () => import('../frontoffice/pages/DetailsMusseView.vue');
const RestaurantsView = () => import('../frontoffice/pages/RestaurantsView.vue');
const EventsView = () => import('../frontoffice/pages/EventsView.vue');
const MusseView = () => import('../frontoffice/pages/MusseView.vue');
const WeekendView = () => import('../frontoffice/pages/WeekendView.vue');
const ActivityView = () => import('../frontoffice/pages/ActivityView.vue');
const ActivityDetailsView = () => import('../frontoffice/pages/ActivityDetailsView.vue');
const VolsFindView = () => import('../frontoffice/pages/VolsFindView.vue');
const MaisonView = () => import('../frontoffice/pages/MaisonView.vue');
const MaisonDetailsView = () => import('../frontoffice/pages/MaisonDetailsView.vue');
const AproposView = () => import('../frontoffice/pages/AproposView.vue');
const TermesView = () => import('../frontoffice/pages/TermesView.vue');
const MissionView = () => import('../frontoffice/pages/MissionView.vue');
const ContactView = () => import('../frontoffice/pages/ContactView.vue');
const AnnuaireHotelView = () => import('../frontoffice/pages/AnnuaireHotelView.vue');
const AnnuaireRestaurantView = () => import('../frontoffice/pages/AnnuaireRestaurantView.vue');
const ConfirmationEventView = () => import('../frontoffice/pages/ConfirmationEventView.vue');
const ReservationEventView = () => import('../frontoffice/pages/ReservationEventView.vue');
const ReservationHotelView = () => import('../frontoffice/pages/ReservationHotelView.vue');
const ReservationRestaurantView = () => import('../frontoffice/pages/ReservationRestaurantView.vue');
const AgilView = () => import('../frontoffice/pages/AgilView.vue');
const GatView = () => import('../frontoffice/pages/GatView.vue');
const ComposeCircuitView = () => import('../frontoffice/pages/ComposeCircuitView.vue');
const CircuitReservationView = () => import('../frontoffice/pages/CircuitReservationView.vue');
const SearchPageView = () => import('../frontoffice/pages/SearchPageView.vue');
const ResetPasswordView = () => import('../frontoffice/pages/ResetPasswordView.vue');
const HotelsFindView = () => import('../frontoffice/pages/HotelsFindView.vue');
const WeekendFindView = () => import('../frontoffice/pages/WeekendFindView.vue');
const HotelsFindDestinationView = () => import('../frontoffice/pages/HotelsFindDestinationView.vue');
const ActivityFindView = () => import('../frontoffice/pages/ActivityFindView.vue');
const HotelsFindCityView = () => import('../frontoffice/pages/HotelsFindCityView.vue');
const RestaurantFindCityView = () => import('../frontoffice/pages/RestaurantFindCityView.vue');
const RestaurantFindStateView = () => import('../frontoffice/pages/RestaurantFindStateView.vue');

const RestaurantFindView = () => import('../frontoffice/pages/RestaurantFindView.vue');
const RestaurantFindRes = () => import('../frontoffice/pages/RestaurantFindRes.vue');
const EventFindView = () => import('../frontoffice/pages/EventFindView.vue');
const HotelsFindStateView = () => import('../frontoffice/pages/HotelsFindStateView.vue');

const EventsFindView = () => import('../frontoffice/pages/EventsFindView.vue');
const TelecomView = () => import('../frontoffice/pages/TelecomView.vue');
const SecourView = () => import('../frontoffice/pages/SecourView.vue');
const MonumentView = () => import('../frontoffice/pages/MonumentView.vue');
const FestivalView = () => import('../frontoffice/pages/FestivalView.vue');
const MonumentDetailsView = () => import('../frontoffice/pages/MonumentDetailsView.vue');
const FestivalDetailsView = () => import('../frontoffice/pages/FestivalDetailsView.vue');
const StatesView = () => import('../frontoffice/pages/StatesView.vue');
const DestinationView = () => import('../frontoffice/pages/DestinationView.vue');
const CityView = () => import('../frontoffice/pages/CityView.vue');
const MaisonFindView = () => import('../frontoffice/pages/MaisonFindView.vue');
const UserView = () => import('../frontoffice/pages/UserView.vue');
const ConfirmationCircuitView=()=>import('../frontoffice/pages/ConfirmationCircuitView.vue');
const ActivityFindStateView=()=> import('../frontoffice/pages/ActivityFindStateView.vue');
const MaisonFindStateView=()=> import('../frontoffice/pages/MaisonFindStateView.vue');
const EventFindStateView=()=> import('../frontoffice/pages/EventFindStateView.vue');
const IteneraireView=()=> import('../frontoffice/pages/IteneraireView.vue');
const PayementView=()=> import('../frontoffice/pages/PayementView.vue');
const PayementSuccessView=()=> import('../frontoffice/pages/PayementSuccessView.vue');
const PayementEchecView=()=> import('../frontoffice/pages/PayementEchecView.vue');
const CGVView=()=>import('../frontoffice/pages/CGVView.vue');
const GuideView=()=>import('../frontoffice/pages/GuideView.vue');
const CircuitsView=()=>import('../frontoffice/pages/CircuitsView.vue');
const CircuitsDetailsView=()=>import('../frontoffice/pages/CircuitsDetailsView.vue');
const AllGuides=()=>import('../frontoffice/pages/AllGuidesView.vue');
const GuideDetailsView=()=> import('../frontoffice/pages/GuideDetailsView.vue');
const JewelryView=()=> import('../frontoffice/pages/JewelryView.vue');
const ChechiaView=()=> import('../frontoffice/pages/ChechiaView.vue');

const PageNotFound = () => import('../frontoffice/pages/PageNotFound.vue');

const routes = [
  {
    path: '/:lang(en|fr|ar)?',
    component: FrontLayout,
    children: [
      { path: '', 
        component: HomeView,
        name:'HomeView',
        meta: {
          title: 'Page d\'accueil - Tunisia Go Travel',
          metaTitle: 'Page d\'accueil',
          metaDescription: 'Découvrez les meilleures offres et services de tourisme en Tunisie.'
        },
       },
       {
        path: 'cgv',
        component: CGVView,
       },
       {
        path: 'allguides',
        component: AllGuides,
       },
       {
        path: 'jewelry',
        component: JewelryView,
       },
       {
        path: 'cuivre',
        component: CuivreView,
       },
       {
        path: 'pottery',
        component: PoterieView,
       },
       {
        path: 'carpet',
        component: TapisView,
       },
       {
        path: 'basketry',
        component: VannerieView,
       },
       {
        path: 'leather',
        component: LeatherView,
       },
       {
        path: 'relaxation',
        component: RelaxationView,
       },
       {
        path: 'health',
        component: SanteMedicalView,
       },
       {
        path: 'aesthetic',
        component: SanteMedecineEsthetique,
       },
       {
        path: 'salons',
        component: SalonCoiffureView,
       },
       {
        path: 'fitness',
        component: CentresRemiseEnforme,
       },
       {
        path: 'chechia',
        component: ChechiaView,
       },
       {
        path: 'allguides/:id',
        component: GuideDetailsView,
        props: true,
       },
       {
        path: 'circuits',
        component: CircuitsView,
       },
       {
        path: 'circuits/:slug',
        component: CircuitsDetailsView,
        props: true,
       },
       {
        path: 'payment',
        component: PayementView,
       },
       {
        path: 'payment/success',
        component: PayementSuccessView,
        props: (route) => ({ query: route.query.order })
       },
       {
        path: 'payment/echec',
        component: PayementEchecView,
        props: (route) => ({ query: route.query.order })
       },
       { path: 'user', 
        component: UserView,
        meta: { requiresAuth: true },
       },
       { path: 'guide', 
        component: GuideView,
        meta: { requiresAuth: true },
       },
      { path: 'search', 
        component: SearchPageView,
        name: 'search', 
        props: (route) => ({ query: route.query.q })
      },
      { path: 'states/:slug', 
        component: StatesView,
        name: 'StatesView', 
        props: true,
      },
      { path: 'iteneraire/:lat/:lng/:lat1/:lng1', 
        component: IteneraireView,
        name: 'IteneraireView', 
        props: true,
      },
      { path: 'city/:slug', 
        component: CityView,
        name: 'CityView', 
        props: true,
      },
      { path: 'destination/:slug', 
        component: DestinationView,
        name: 'DestinationView', 
        props: true,
      },
      {
        path: 'apropos',
        component: AproposView,
        meta: {
          title: 'À propos de Tunisia Go Travel',
          metaTitle: 'À propos de notre service',
          metaDescription: 'Découvrez l\'histoire et la mission de Tunisia Go Travel, votre guide de voyage en Tunisie.'
        }
      },
      {
        path: 'monument',
        component: MonumentView,
        meta: {
          title: 'Monuments Historiques - Tunisia Go Travel',
          metaTitle: 'Découvrez les Monuments en Tunisie',
          metaDescription: 'Explorez les monuments historiques incontournables en Tunisie et plongez dans l\'histoire du pays.'
        }
      },
      { path: 'monument/:id', component: MonumentDetailsView,
        name: 'MonumentDetailsView',
        props: true,
       },
       { path: 'festival/:id', component: FestivalDetailsView,
        name: 'FestivalDetailsView',
        props: true,
       },
       {
        path: 'festival',
        component: FestivalView,
        meta: {
          title: 'Festivals en Tunisie - Tunisia Go Travel',
          metaTitle: 'Découvrez les Festivals en Tunisie',
          metaDescription: 'Explorez les festivals incontournables en Tunisie, une destination riche en événements culturels et festifs.'
        }
      }
      ,
      {
        path: 'secour',
        component: SecourView,
        meta: {
          title: 'Secours en Tunisie - Tunisia Go Travel',
          metaTitle: 'Services de Secours en Tunisie',
          metaDescription: 'Découvrez les services de secours disponibles en Tunisie pour assurer votre sécurité et bien-être.'
        }
      }
      ,
      { path: 'confirmation', component: ConfirmationEventView },
      { path: 'circuits/confirmation', component: ConfirmationCircuitView },
      {
        path: 'termes',
        component: TermesView,
        meta: {
          title: 'Termes et Conditions - Tunisia Go Travel',
          metaTitle: 'Termes et Conditions',
          metaDescription: 'Consultez les termes et conditions d\'utilisation de Tunisia Go Travel pour connaître les règles et politiques applicables.'
        }
      }
      ,
      {
        path: 'mission',
        component: MissionView,
        meta: {
          title: 'Notre Mission - Tunisia Go Travel',
          metaTitle: 'Mission de Tunisia Go Travel',
          metaDescription: 'Découvrez la mission de Tunisia Go Travel et notre engagement à offrir les meilleures expériences de voyage en Tunisie.'
        }
      }
      ,
      { path: 'hotels', component: HotelsView,
        meta: {
          title: 'Nos Hôtels - Tunisia Go Travel',
          metaTitle: 'Hôtels en Tunisie',
          metaDescription: 'Découvrez une sélection d\'hôtels de qualité en Tunisie.'
        },
       },
       {
        path: 'hotels/annuaire',
        component: AnnuaireHotelView,
        meta: {
          title: 'Annuaire des Hôtels - Tunisia Go Travel',
          metaTitle: 'Annuaire des Hôtels en Tunisie',
          metaDescription: 'Explorez l\'annuaire des hôtels en Tunisie pour trouver des options de qualité et réserver facilement votre séjour.'
        }
      }
      ,
      {
        path: 'restaurants',
        component: RestaurantsView,
        meta: {
          title: 'Restaurants - Tunisia Go Travel',
          metaTitle: 'Restaurants en Tunisie',
          metaDescription: 'Découvrez une sélection de restaurants en Tunisie offrant une expérience culinaire inoubliable.'
        }
      },
      { path: 'hotels/find/:destination/:date/:endDate/:adults/:rooms/:childs/',
        component: HotelsFindView,
        name:'HotelsFindView',
        props: true
      },
      { path: 'hotels/findHotel/:destination',
        component: HotelsFindDestinationView,
        name:'HotelsFindDestinationView',
        props: true
      },
      { path: 'hotels/findHotel/state/:state',
        component: HotelsFindStateView,
        name:'HotelsFindStateView',
        props: true
      },
      { path: 'hotels/findCity/:destination',
        component: HotelsFindCityView,
        name:'HotelsFindCityView',
        props: true
      },
      { path: 'restaurants/findCity/:destination',
        component: RestaurantFindCityView,
        name:'RestaurantFindCityView',
        props: true
      },
      { path: 'restaurants/findState/:state',
        component: RestaurantFindStateView,
        name:'RestaurantFindStateView',
        props: true
      },
      { path: 'weekend/findWeekend/:destination',
        component: WeekendFindView,
        name:'WeekendFindView',
        props: true
      },
      { path: 'activity/findActivity/:destination',
        component: ActivityFindView,
        name:'ActivityFindView',
        props: true
      },
      { path: 'activity/findActivity/state/:state',
        component: ActivityFindStateView,
        name:'ActivityFindStateView',
        props: true
      },
      { path: 'maison/findMaison/:destination',
        component: MaisonFindView,
        name:'MaisonFindView',
        props: true
      },
      { path: 'maison/findMaison/state/:state',
        component: MaisonFindStateView,
        name:'MaisonFindStateView',
        props: true
      },
      { path: 'restaurants/find/:destination/:date/:number',
        component: RestaurantFindView,
        name:'RestaurantFindView',
        props: true
      },
      { path: 'restaurants/findRes/:destination',
        component: RestaurantFindRes,
        name:'RestaurantFindRes',
        props: true
      },
      { path: 'events/find/:destination',
        component: EventsFindView,
        name:'EventsFindView',
        props: true
      },
      { path: 'events/findEvent/:destination',
        component: EventFindView,
        name:'EventFindView',
        props: true
      },
      { path: 'events/findEvent/state/:destination',
        component: EventFindStateView,
        name:'EventFindStateView',
        props: true
      },
      { path: 'restaurants/reservation', component: ReservationRestaurantView },
      {
        path: 'restaurants/annuaire',
        component: AnnuaireRestaurantView,
        meta: {
          title: 'Annuaire des Restaurants - Tunisia Go Travel',
          metaTitle: 'Annuaire des Restaurants en Tunisie',
          metaDescription: 'Explorez l\'annuaire complet des restaurants en Tunisie pour trouver les meilleures options culinaires dans le pays.'
        }
      }
      ,
      {
        path: 'events',
        component: EventsView,
        meta: {
          title: 'Événements - Tunisia Go Travel',
          metaTitle: 'Découvrez les Événements en Tunisie',
          metaDescription: 'Explorez les événements à venir en Tunisie et planifiez votre prochaine aventure culturelle.'
        }
      }
      ,
      { path: 'events/reservation/:id', component: ReservationEventView,
        name: 'ReservationEventView',
        props: true,
       },
      { path: 'hotels/reservation', component: ReservationHotelView },
      {
        path: 'maison',
        component: MaisonView,
        meta: {
          title: 'Maison - Tunisia Go Travel',
          metaTitle: 'Découvrez nos Maisons en Tunisie',
          metaDescription: 'Explorez une sélection de maisons uniques à louer ou acheter en Tunisie.'
        }
      }
      ,
      { path: 'vols/find/:departAirport/:arriveAirport', component: VolsFindView },
      { path: 'maison/:id', 
        component: MaisonDetailsView,        
        name: 'MaisonDetailsView',
        props: true, },
        {
          path: 'musses',
          component: MusseView,
          meta: {
            title: 'Musées - Tunisia Go Travel',
            metaTitle: 'Explorez les Musées en Tunisie',
            metaDescription: 'Découvrez les musées fascinants à visiter en Tunisie et explorez le riche patrimoine culturel du pays.'
          }
        }
        ,
        {
          path: 'weekend',
          component: WeekendView,
          meta: {
            title: 'Weekends - Tunisia Go Travel',
            metaTitle: 'Les Meilleurs Weekends en Tunisie',
            metaDescription: 'Découvrez des idées de week-ends inoubliables à travers la Tunisie.'
          }
        }
        ,
        {
          path: 'contact',
          component: ContactView,
          meta: {
            title: 'Contactez-nous - Tunisia Go Travel',
            metaTitle: 'Contactez-nous pour plus d\'informations',
            metaDescription: 'Contactez l\'équipe de Tunisia Go Travel pour toute question ou demande d\'information.'
          }
        }
        ,
        {
          path: 'activity',
          component: ActivityView,
          meta: {
            title: 'Activités de loisirs - Tunisia Go Travel',
            metaTitle: 'Découvrez les meilleures activités en Tunisie',
            metaDescription: 'Explorez une variété d\'activités passionnantes à faire en Tunisie pour tous les goûts et tous les âges.'
          }
        }
        ,
      { path: 'activity/:id', 
        component: ActivityDetailsView,
        name: 'ActivityDetailsView',
        props: true,
      },
      {
        path: 'vehicules',
        component: VehiculesView,
        meta: {
          title: 'Voitures de location - Tunisia Go Travel',
          metaTitle: 'Louez une voiture en Tunisie',
          metaDescription: 'Trouvez la voiture idéale pour votre voyage en Tunisie parmi une large sélection de véhicules disponibles.'
        }
      }
      ,
      {
        path: 'circuits/compose',
        component: ComposeCircuitView,
        meta: {
          title: 'Créer un circuit - Tunisia Go Travel',
          metaTitle: 'Composez votre circuit en Tunisie',
          metaDescription: 'Personnalisez votre propre circuit et découvrez les meilleures destinations en Tunisie selon vos préférences.'
        }
      }
      ,
      { path: 'circuits/reservation', component: CircuitReservationView },
      {
        path: 'agil',
        component: AgilView,
        meta: {
          title: 'Agil - Tunisia Go Travel',
          metaTitle: 'Découvrez Agil en Tunisie',
          metaDescription: 'Explorez l\'agilité et les services offerts par Agil en Tunisie pour une expérience de voyage unique.'
        }
      }
      ,
      {
        path: 'tt',
        component: TelecomView,
        meta: {
          title: 'Telecom - Tunisia Go Travel',
          metaTitle: 'Explorez les services télécoms en Tunisie',
          metaDescription: 'Découvrez les meilleures options de télécommunications et services liés à la connectivité en Tunisie.'
        }
      }
      ,
      {
        path: 'gat',
        component: GatView,
        meta: {
          title: 'Gat - Tunisia Go Travel',
          metaTitle: 'Explorez les destinations de Gat en Tunisie',
          metaDescription: 'Découvrez les attractions et activités incontournables à Gat, une destination populaire en Tunisie.'
        }
      }
      ,
      { path: 'reset-password/:token/:email', 
        component: ResetPasswordView,
        name: 'ResetPasswordView',
        props: true,
      },
      {
        path: 'hotels/:id',
        component: DetailsHotelsView,
        name: 'DetailsHotelsView',
        props: true,
      },
      {
        path: 'restaurants/:id',
        component: DetailsRestaurantsView,
        name: 'DetailsRestaurantsView',
        props: true,
      },
      {
        path: 'events/:id',
        component: DetailsEventsView,
        name: 'DetailsEventsView',
        props: true,
      },
      {
        path: 'musses/:id',
        component: DetailsMusseView,
        name: 'DetailsMusseView',
        props: true,
      },  
      {
        path: "/:pathMatch(.*)*",
        name: "PageNotFound",
        component: PageNotFound,
      },  
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line
  scrollBehavior(to, from, savedPosition) {
    // Always scroll to the top
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  AOS.init();
  const lang = to.params.lang || 'fr'; // Default to French
  if (!['en', 'fr', 'ar'].includes(lang)) {
    return next('/fr'); // Redirect invalid languages to default
  }
  const isAuthenticated = localStorage.getItem('token'); 
   
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'HomeView' }); 
  } 
    next(); 
});
export default router;
