<template>
  <div class="swiper-containerBanner rounded-lg">
    <swiper 
      :spaceBetween="12" 
      :pagination="{ clickable: true }"
      :autoplay="{ delay: 3000, disableOnInteraction: false }" 
      :modules="modules" 
      class="mySwiperBanner rounded-lg"
    >
      <swiper-slide 
        v-for="(image, index) in images" 
        :key="index" 
        class="rounded-lg flex items-center justify-center"
      >
      <a :href="image.link" class="block w-full rounded-lg h-64 lg:h-[18.75rem]" >
  <template v-if="image.type === 'video'">
    <iframe
      :src="`${image.source}?autoplay=1&mute=1`"
      allow="autoplay; encrypted-media"
      class="w-full h-48 lg:h-72 object-contain rounded-lg"
      allowfullscreen
    ></iframe>
  </template>
  <template v-else>
    <img 
      :src="image.source" 
      alt="Slide Tunisia Go Travel" 
      class="w-full h-56 lg:h-72 object-fill rounded-lg" 
    />
  </template>
</a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay } from 'swiper/modules';

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  props: {
    images: {
      type: Array,
      required: true
    }
  }
};
</script>
<style scoped>
.swiper-container {
  width: 100%;
  height: 20rem;
}
@media (max-width: 768px) { /* Adjust breakpoint as needed */
  .swiper-containerBanner {
    height: 16rem;/* Smaller screens */
    }
  }
.mySwiperBanner {
  padding-bottom: 20px; /* Space for pagination */
}
.swiper-paginationBanner {
  bottom: 10px;
}
</style>