import { createStore } from 'vuex';
import axios from 'axios';
import localforage from 'localforage';
export default createStore({
    state: {
        burreauTourism:[],
        postTunisie:[],
        allFestival:[],
        allmonument:[],
        allGuides:[],
        artisanats:[],
        mediacals:[],
        voyages:[],
        reservationHotelCircuit:[],
        reservationRestaurantCircuit:[],
        banners:[],
        weekend:[],
        language: 'fr',
        city:[],
        cars:[],
        agil:[],
        airports:[],
        states:[],
        destinations:[],
        musses:[],
        hotels:[],
        maison:[],
        hotelByState:{},
        restaurantByState:{},
        eventByState:{},
        activetyByState:{},
        restaurants:[],
        specialHotels:[],
        specialRestaurants:[],
        specialMaison:[],
        activities:[],
        events:[],
        event:{},
        musse:{},
        hotelDestination:[],
        hotelsByPage:{},
        restaurantByPage:{},
        eventsByPage:{},
        musseByPage:{},
        weekendByPage:{},
        activityByPage:{},
        maisonByPage:{},
        carByPage:{},
        roomSelected:[],
        hotelSelected:{},
        restaurantDestination:[],
        activitiesDestination:[],
        articles:[],
        reservationHotels: [],
        reservationEvents: [],
        reservationsCircuits:[],
        reservationRestaurants:[],
        profile: {},
        gat:[],
        tunisieTelecom:[],
        token:"",
        name:"",
        image:"",
        user:{},
        reservationSelected:{},
        restaurantSelected:{},
        circuitSelected:{},
        isLoading:false,
        monument:{},
        festival:{}
    },
    mutations: {
        SET_ARTISANATS(state,artisanats){
           state.artisanats=artisanats;
        },
        SET_MEDICALS(state,medicals){
           state.mediacals=medicals;
        },
        SET_VOYAGES(state,voyages){
           state.voyages=voyages;
        },
        SET_GUIDES(state,allGuides){
            state.allGuides=allGuides;
         },
        setLanguage(state, lang) {
            state.language = lang;
        },
        SET_RESERVATION_HOTELS_CIRCUITS(state,reservationHotelCircuit){
            if (reservationHotelCircuit && reservationHotelCircuit.id) {
                const index = state.reservationHotelCircuit.findIndex(
                  (item) => item.id === reservationHotelCircuit.id
                );             
                if (index !== -1) {
                  state.reservationHotelCircuit.splice(index, 1);
                }       
                state.reservationHotelCircuit.push(reservationHotelCircuit);
              }
        },
        SET_RESERVATION_RESTAURANTS_CIRCUITS(state,reservationCircuit){
            if (reservationCircuit && reservationCircuit.id) {
                const index = state.reservationRestaurantCircuit.findIndex(
                  (item) => item.id === reservationCircuit.id
                );             
                if (index !== -1) {
                  state.reservationRestaurantCircuit.splice(index, 1);
                }       
                state.reservationRestaurantCircuit.push(reservationCircuit);
              }
        },
        SET_HOTEL_BY_STATE(state,hotelByState){
             state.hotelByState=hotelByState
        },
        SET_RESTAURANT_BY_STATE(state,restaurantByState){
            state.restaurantByState=restaurantByState;
        },
        SET_ACTIVETY_BY_STATE(state,activetyByState){
            state.activetyByState=activetyByState;
        },
        SET_EVENT_BY_STATE(state,eventByState){
            state.eventByState=eventByState;
        },
        SET_LOADING(state,isLoading){
            state.isLoading=isLoading;
        },
        SET_NAME(state,name){
            state.name=name;
        },
        SET_IMAGE(state,image){
            state.image=image;
        },
        SET_RESERVATION_HOTELS(state,reservationHotels){
            state.reservationHotels=reservationHotels;
        },
        SET_RESERVATION_RESTAURANTS(state,reservationRestaurants){
            state.reservationRestaurants=reservationRestaurants;
        },
        SET_RESERVATION_CIRCUITS(state,reservationsCircuits){
            state.reservationsCircuits=reservationsCircuits;
        },
        SET_RESERVATION_EVENTS(state,reservationEvents){
            state.reservationEvents=reservationEvents;
        },
        SET_PROFILE(state,profile){
            state.profile=profile;
        },
        SET_BURREAU_TOURISME(state,burreauTourism){
            state.burreauTourism=burreauTourism;
        },
        SET_POSTE_TUNISIE(state,postTunisie){
            state.postTunisie=postTunisie;
        },
        SET_ALL_FESTIVAL(state,allFestival){
            state.allFestival=allFestival;
        },
        SET_MONUMENT(state,allmonument){
            state.allmonument=allmonument;
        },
        SET_TT(state,tunisieTelecom){
            state.tunisieTelecom=tunisieTelecom;
        },
        SET_SELECTED_CIRCUIT(state,circuitSelected){
            state.circuitSelected=circuitSelected;
        },
        SET_USER(state,user){
            state.user=user;
        },
        SET_TOKEN(state,token){
            state.token=token;
        },
        SET_SELECTED_RESERVATION(state,reservationSelected){
            state.reservationSelected=reservationSelected;
        },
        SET_AGIL(state,agil){
            state.agil=agil;
        },
        SET_GAT(state,gat){
            state.gat=gat;
        },
        SET_SELECTED_RESTAURANT(state,restaurantSelected){
            state.restaurantSelected=restaurantSelected;
        },
        SET_SELECTED_ROOM(state,roomSelected){
            state.roomSelected=roomSelected;
        },
        SET_SELECTED_HOTEL(state,hotelSelected){
           state.hotelSelected=hotelSelected;
        },
        SET_EVENT(state, event) {
            state.event = event;
        },
        SET_MAISON_BY_PAGE(state, maisonByPage){
           state.maisonByPage=maisonByPage
        },
        SET_MUSSE_BY_PAGE(state, musse){
            state.musseByPage=musse
         },
        SET_WEEKEND_BY_PAGE(state, weekend){
            state.weekendByPage=weekend
         },
        SET_ACTIVITY_BY_PAGE(state, activity){
            state.activityByPage=activity
        },
        SET_CAR_BY_PAGE(state, car){
            state.carByPage=car
        },
        SET_EVENTS_BY_PAGE(state, eventsByPage) {
            state.eventsByPage = eventsByPage;
        },
        SET_HOTELS_BY_PAGE(state, hotelsByPage) {
            state.hotelsByPage = hotelsByPage;
        },
        SET_RESTAURANT_BY_PAGE(state, restaurantsByPage) {
            state.restaurantsByPage = restaurantsByPage;
        },
        SET_MUSSE(state, musse) {
            state.musse = musse;
        },
        SET_MONUMEN(state, monument) {
            state.monument = monument;
        },
        SET_FESTIVAL(state, festival) {
            state.festival = festival;
        },
        SET_ARTICLES(state, articles) {
            state.articles = articles;
        },
        SET_CARS(state, cars) {
            state.cars = cars;
        },
        SET_HOTELS_DESTINATIONS(state, hotelsDestination) {
            state.hotelsDestination = hotelsDestination;
        },
        SET_ACTIVITIES_DESTINATIONS(state, activitiesDestination) {
            state.activitiesDestination = activitiesDestination;
        },
        SET_RESTAURANTS_DESTINATIONS(state, restaurantDestination) {
            state.restaurantDestination = restaurantDestination;
          },
        SET_EVENTS(state, events) {
            state.events = events;
        },
        SET_MUSSES(state, musses) {
            state.musses = musses;
        },
        SET_ACTIVITY(state, activities) {
            state.activities = activities;
        },
        SET_CITY(state, city) {
            state.city = city;
        },
        SET_STATES(state, states) {
            state.states = states;
        },
        SET_AIRPORTS(state, airports) {
            state.airports = airports;
        },
        SET_WEEKEND(state, weekend) {
            state.weekend = weekend;
        },
        SET_BANNERS(state, banners) {
            state.banners = banners;
        },
        SET_DESTINATIONS(state, destinations) {
            state.destinations = destinations;
        },
        SET_HOTELS(state, hotels) {
            state.hotels = hotels;
        },
        SET_RESTAURANTS(state, restaurants) {
            state.restaurants = restaurants;
        },
        SET_MAISON(state, maisons) {
            state.maisons = maisons;
        },
        SET_SPECIAL_MAISON(state, specialMaisons) {
            state.specialMaisons = specialMaisons;
        },
        SET_SPECIAL_HOTELS(state, specialMaisons) {
            state.specialMaisons = specialMaisons;
        },
        SET_SPECIAL_RESTAURANTS(state, specialRestaurants) {
            state.specialRestaurants = specialRestaurants;
        },
    },
    actions: {
        changeLanguage({ commit }, lang) {
            commit('setLanguage', lang);
        },
        setLoading({commit},status){
           commit("SET_LOADING",status);
        },
        async clearLocalForage() {
            try {
                await localforage.clear();
                console.log("LocalForage data cleared.");
            } catch (error) {
                console.error("Error clearing LocalForage:", error);
            }
        },
        startAutoClear({ dispatch }) {
            setInterval(() => {
                dispatch("clearLocalForage");
            }, 60 * 60 * 1000); // 1 hours in milliseconds
        },
        // eslint-disable-next-line
        async fetchBanners({ commit, state }) {
            // Check if data exists in IndexedDB
            const cachedBanners = await localforage.getItem('banners');
            if (cachedBanners) {
              commit('SET_BANNERS', cachedBanners);
              return cachedBanners;
            }
      
            // Fetch data from API if not in IndexedDB
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}a_d_s_f_ront`);
              const banners = response.data.states;
              commit('SET_BANNERS', banners);
              await localforage.setItem('banners', banners); // Save to IndexedDB
              return banners;
            } catch (error) {
              console.error('Error fetching Banners:', error);
            }
        },
        // eslint-disable-next-line
        async fetchArtisanats({ commit, state }) {
            // Check if data exists in IndexedDB
            const cachedBanners = await localforage.getItem('artisanat');
            if (cachedBanners) {
              commit('SET_ARTISANATS', cachedBanners);
              return cachedBanners;
            }
      
            // Fetch data from API if not in IndexedDB
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}artisanat`);
              const artisanat = response.data.data;
              commit('SET_ARTISANATS', artisanat);
              await localforage.setItem('artisanat', artisanat); // Save to IndexedDB
              return artisanat;
            } catch (error) {
              console.error('Error fetching artisanat:', error);
            }
        },
        // eslint-disable-next-line
        async fetchMedicals({ commit, state }) {
            // Check if data exists in IndexedDB
            const cachedBanners = await localforage.getItem('medical');
            if (cachedBanners) {
                commit('SET_MEDICALS', cachedBanners);
                return cachedBanners;
            }
              
            // Fetch data from API if not in IndexedDB
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}medical-category`);
                const medical = response.data;
                commit('SET_MEDICALS', medical);
                await localforage.setItem('medical', medical); // Save to IndexedDB
                return medical;
            } catch (error) {
                console.error('Error fetching artisanat:', error);
            }
        },
           // eslint-disable-next-line
          async fetchDestinations({ commit, state }) {
            // Check if data exists in IndexedDB
            const cachedDestinations = await localforage.getItem('destinations');
            if (cachedDestinations) {
              commit('SET_DESTINATIONS', cachedDestinations);
              return cachedDestinations;
            }
          
            // Fetch data from API if not in IndexedDB
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}alldestinations`);
              const destinations = response.data.destinations;
              commit('SET_DESTINATIONS', destinations);
              await localforage.setItem('destinations', destinations); // Save to IndexedDB
              return destinations;
            } catch (error) {
              console.error('Error fetching destinations:', error);
            }
        },        
        async fetchHotels({ commit, state }) {
            // Check if hotels are already in the state
            if (state.hotels.length === 0) {
                try {
                    // Try to get hotels from localForage first
                    const cachedHotels = await localforage.getItem('hotels');
                    if (cachedHotels) {
                        // If cached hotels exist, commit them to the state
                        commit('SET_HOTELS', cachedHotels);
                        return cachedHotels;
                    } else {
                        // If no cached hotels, fetch from the backend
                        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allhotels`);
                        const hotels = response.data.hotels;
        
                        // Commit the fetched hotels to the state
                        commit('SET_HOTELS', hotels);
        
                        // Cache the hotels in localForage for future use
                        await localforage.setItem('hotels', hotels);
        
                        return hotels;
                    }
                } catch (error) {
                    console.error('Error fetching hotels:', error);
                }
            } else {
                // If hotels are already in the state, return them
                return state.hotels;
            }
        },
        async fetchMaison({ commit, state }) {
            // Check if data exists in localforage
            const cachedMaisons = await localforage.getItem('maisons');

            // If cached data exists, use it
            if (cachedMaisons && state.maison.length === 0) {
                commit('SET_MAISON', cachedMaisons);
                return cachedMaisons;
            }

            // Otherwise, fetch from API
            if (state.maison.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allmaison`);
                    const maisons = response.data.maisons;
                    commit('SET_MAISON', maisons);
                    await localforage.setItem('maisons', maisons);

                    return maisons;
                } catch (error) {
                    console.error('Error fetching maisons:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.maison;
            }
        },
        async fetchRestaurants({ commit, state }) {
            // Check if data exists in localforage
            const cachedRestaurants = await localforage.getItem('restaurants');

            // If cached data exists, use it
            if (cachedRestaurants && state.restaurants.length === 0) {
                commit('SET_RESTAURANTS', cachedRestaurants);
                return cachedRestaurants;
            }

            // Otherwise, fetch from API
            if (state.restaurants.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allrestaurants`);
                    const restaurants = response.data.restaurants;

                    // Store data in Vuex state and localforage
                    commit('SET_RESTAURANTS', restaurants);
                    await localforage.setItem('restaurants', restaurants);

                    return restaurants;
                } catch (error) {
                    console.error('Error fetching restaurants:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.restaurants;
            }
        },
        async fetchSpecialHotels({ commit, state }) {
            // Check if data exists in localforage
            const cachedSpecialHotels = await localforage.getItem('specialHotels');

            // If cached data exists, use it
            if (cachedSpecialHotels && state.specialHotels.length === 0) {
                commit('SET_SPECIAL_HOTELS', cachedSpecialHotels);
                return cachedSpecialHotels;
            }

            // Otherwise, fetch from API
            if (state.specialHotels.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}hotelsspecial`);
                    const hotelsspecial = response.data.hotels.data;

                    // Store data in Vuex state and localforage
                    commit('SET_SPECIAL_HOTELS', hotelsspecial);
                    await localforage.setItem('specialHotels', hotelsspecial);

                    return hotelsspecial;
                } catch (error) {
                    console.error('Error fetching special hotels:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.specialHotels;
            }
        },
        async fetchSpecialRestaurants({ commit, state }) {
            // Check if data exists in localforage
            const cachedSpecialRestaurants = await localforage.getItem('specialRestaurants');

            // If cached data exists, use it
            if (cachedSpecialRestaurants && state.specialRestaurants.length === 0) {
                commit('SET_SPECIAL_RESTAURANTS', cachedSpecialRestaurants);
                return cachedSpecialRestaurants;
            }

            // Otherwise, fetch from API
            if (state.specialRestaurants.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurantsspecial`);
                    const restaurantsspecial = response.data.restaurants.data;

                    // Store data in Vuex state and localforage
                    commit('SET_SPECIAL_RESTAURANTS', restaurantsspecial);
                    await localforage.setItem('specialRestaurants', restaurantsspecial);

                    return restaurantsspecial;
                } catch (error) {
                    console.error('Error fetching special restaurants:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.specialRestaurants;
            }
        },
        async fetchSpecialMaisons({ commit, state }) {
            // Check if data exists in localforage
            const cachedSpecialMaisons = await localforage.getItem('specialMaisons');

            // If cached data exists and Vuex state is empty, use it
            if (cachedSpecialMaisons && state.specialMaison.length === 0) {
                commit('SET_SPECIAL_MAISON', cachedSpecialMaisons);
                return cachedSpecialMaisons;
            }

            // Otherwise, fetch from the API
            if (state.specialMaison.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}maisonspecial`);
                    const maisonspecial = response.data.maisons;

                    // Update Vuex state and store in localforage
                    commit('SET_SPECIAL_MAISON', maisonspecial);
                    await localforage.setItem('specialMaisons', maisonspecial);

                    return maisonspecial;
                } catch (error) {
                    console.error('Error fetching special maisons:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.specialMaison;
            }
        },
        async fetchCars({ commit, state }) {
            // Check if data exists in localforage
            const cachedCars = await localforage.getItem('cars');

            // If cached data exists and Vuex state is empty, use it
            if (cachedCars && state.cars.length === 0) {
                commit('SET_CARS', cachedCars);
                return cachedCars;
            }

            // Otherwise, fetch from the API
            if (state.cars.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allcars`);
                    const cars = response.data.cars.data;

                    // Update Vuex state and store in localforage
                    commit('SET_CARS', cars);
                    await localforage.setItem('cars', cars);

                    return cars;
                } catch (error) {
                    console.error('Error fetching cars:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.cars;
            }
        },
        async fetchAirports({ commit, state }) {
            // Check if data exists in localforage
            const cachedAirports = await localforage.getItem('airports');

            // If cached data exists and Vuex state is empty, use it
            if (cachedAirports && state.airports.length === 0) {
                commit('SET_AIRPORTS', cachedAirports);
                return cachedAirports;
            }

            // Otherwise, fetch from the API
            if (state.airports.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}airport`);
                    const airports = response.data.airports;

                    // Update Vuex state and store in localforage
                    commit('SET_AIRPORTS', airports);
                    await localforage.setItem('airports', airports);

                    return airports;
                } catch (error) {
                    console.error('Error fetching airports:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.airports;
            }
        },
        async fetchWeekend({ commit, state }) {
            // Check if data exists in localforage
            const cachedWeekend = await localforage.getItem('weekend');

            // If cached data exists and Vuex state is empty, use it
            if (cachedWeekend && state.weekend.length === 0) {
                commit('SET_WEEKEND', cachedWeekend);
                return cachedWeekend;
            }

            // Otherwise, fetch from the API
            if (state.weekend.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}weekend`);
                    const weekend = response.data.events.data;

                    // Update Vuex state and store in localforage
                    commit('SET_WEEKEND', weekend);
                    await localforage.setItem('weekend', weekend);

                    return weekend;
                } catch (error) {
                    console.error('Error fetching weekend:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.weekend;
            }
        },
        async fetchStates({ commit, state }) {
            // Check if data exists in localforage
            const cachedStates = await localforage.getItem('states');

            // If cached data exists and Vuex state is empty, use it
            if (cachedStates && state.states.length === 0) {
                commit('SET_STATES', cachedStates);
                return cachedStates;
            }

            // Otherwise, fetch from the API
            if (state.states.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}states`);
                    const states = response.data.states;

                    // Update Vuex state and store in localforage
                    commit('SET_STATES', states);
                    await localforage.setItem('states', states);

                    return states;
                } catch (error) {
                    console.error('Error fetching states:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.states;
            }
        },
        async fetchAgil({ commit, state }) {
            // Check if data exists in localforage
            const cachedAgil = await localforage.getItem('agil');

            // If cached data exists and Vuex state is empty, use it
            if (cachedAgil && state.agil.length === 0) {
                commit('SET_AGIL', cachedAgil);
                return cachedAgil;
            }

            // Otherwise, fetch from the API
            if (state.agil.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}agil`);
                    const agil = response.data.agil;

                    // Update Vuex state and store in localforage
                    commit('SET_AGIL', agil);
                    await localforage.setItem('agil', agil);

                    return agil;
                } catch (error) {
                    console.error('Error fetching agil:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.agil;
            }
        },
        async fetchTT({ commit, state }) {
            // Check if data exists in localforage
            const cachedTT = await localforage.getItem('tunisietelecom');

            // If cached data exists and Vuex state is empty, use it
            if (cachedTT && state.tunisieTelecom.length === 0) {
                commit('SET_TT', cachedTT);
                return cachedTT;
            }

            // Otherwise, fetch from the API
            if (state.tunisieTelecom.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}tunisietelecom`);
                    const tt = response.data.tunisietelecom;

                    // Update Vuex state and store in localforage
                    commit('SET_TT', tt);
                    await localforage.setItem('tunisietelecom', tt);

                    return tt;
                } catch (error) {
                    console.error('Error fetching tunisietelecom:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.tunisieTelecom;
            }
        },
        async fetchGat({ commit, state }) {
            // Check if data exists in localforage
            const cachedGat = await localforage.getItem('gat');

            // If cached data exists and Vuex state is empty, use it
            if (cachedGat && state.gat.length === 0) {
                commit('SET_GAT', cachedGat);
                return cachedGat;
            }

            // Otherwise, fetch from the API
            if (state.gat.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}gat`);
                    const gat = response.data.gat;

                    // Update Vuex state and store in localforage
                    commit('SET_GAT', gat);
                    await localforage.setItem('gat', gat);

                    return gat;
                } catch (error) {
                    console.error('Error fetching gat:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.gat;
            }
        },
        async fetchCity({ commit, state }) {
            // Check if data exists in localforage
            const cachedCity = await localforage.getItem('city');

            // If cached data exists and Vuex state is empty, use it
            if (cachedCity && state.city.length === 0) {
                commit('SET_CITY', cachedCity);
                return cachedCity;
            }

            // Otherwise, fetch from the API
            if (state.city.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}city`);
                    const city = response.data.city;

                    // Update Vuex state and store in localforage
                    commit('SET_CITY', city);
                    await localforage.setItem('city', city);

                    return city;
                } catch (error) {
                    console.error('Error fetching city:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.city;
            }
        },
        async fetchMusse({ commit, state }) {
            // Check if data exists in localforage
            const cachedMusse = await localforage.getItem('musses');

            // If cached data exists and Vuex state is empty, use it
            if (cachedMusse && state.musses.length === 0) {
                commit('SET_MUSSES', cachedMusse);
                return cachedMusse;
            }

            // Otherwise, fetch from the API
            if (state.musses.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees`);
                    const musses = response.data.musees.data;

                    // Update Vuex state and store in localforage
                    commit('SET_MUSSES', musses);
                    await localforage.setItem('musses', musses);

                    return musses;
                } catch (error) {
                    console.error('Error fetching musses:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.musses;
            }
        },
        async fetchActivity({ commit, state }) {
            // Check if data exists in localforage
            const cachedActivity = await localforage.getItem('activities');

            // If cached data exists and Vuex state is empty, use it
            if (cachedActivity && state.activities.length === 0) {
                commit('SET_ACTIVITY', cachedActivity);
                return cachedActivity;
            }

            // Otherwise, fetch from the API
            if (state.activities.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety`);
                    const activity = response.data.activety.data;

                    // Update Vuex state and store in localforage
                    commit('SET_ACTIVITY', activity);
                    await localforage.setItem('activities', activity);

                    return activity;
                } catch (error) {
                    console.error('Error fetching activities:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.activities;
            }
        },
        async fetchEvents({ commit, state }) {  
            if (state.events.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allevent`);
                    const events = response.data.events.data;
                    commit('SET_EVENTS', events);
                    return events;
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
            else{
                return state.events;
            }
        },
        async fetchGuides({ commit, state }) {  
            if (state.allGuides.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}guide`);
                    const guides = response.data;
                    commit('SET_GUIDES', guides);
                    return guides;
                } catch (error) {
                    console.error('Error fetching events:', error);
                }
            }
            else{
                return state.allGuides;
            }
        },
        async fetchBurreauTourisme({ commit, state }) {
            // Check if data exists in localforage
            const cachedBurreauTourisme = await localforage.getItem('bureautourisme');

            // If cached data exists and Vuex state is empty, use it
            if (cachedBurreauTourisme && state.burreauTourism.length === 0) {
                commit('SET_BURREAU_TOURISME', cachedBurreauTourisme);
                return cachedBurreauTourisme;
            }

            // Otherwise, fetch from the API
            if (state.burreauTourism.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}bureautourisme`);
                    const bureautourisme = response.data.bureautourisme;

                    // Update Vuex state and store in localforage
                    commit('SET_BURREAU_TOURISME', bureautourisme);
                    await localforage.setItem('bureautourisme', bureautourisme);

                    return bureautourisme;
                } catch (error) {
                    console.error('Error fetching bureautourisme:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.burreauTourism;
            }
        },
        async fetchPosteTunisie({ commit, state }) {
            // Check if data exists in localforage
            const cachedPosteTunisie = await localforage.getItem('posttunisie');

            // If cached data exists and Vuex state is empty, use it
            if (cachedPosteTunisie && state.postTunisie.length === 0) {
                commit('SET_POSTE_TUNISIE', cachedPosteTunisie);
                return cachedPosteTunisie;
            }

            // Otherwise, fetch from the API
            if (state.postTunisie.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}posttunisie`);
                    const postTunisie = response.data.posttunisie;

                    // Update Vuex state and store in localforage
                    commit('SET_POSTE_TUNISIE', postTunisie);
                    await localforage.setItem('posttunisie', postTunisie);

                    return postTunisie;
                } catch (error) {
                    console.error('Error fetching postTunisie:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.postTunisie;
            }
        },
        async fetchAllFestival({ commit, state }) {  
            if (state.allFestival.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}festival`);
                    const festival = response.data.festival;
                    commit('SET_ALL_FESTIVAL', festival);
                    return festival;
                } catch (error) {
                    console.error('Error fetching festival:', error);
                }
            }
            else{
                return state.allFestival;
            }
        },
        // eslint-disable-next-line
        async fetchAllMonument({ commit, state }) {  
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}monument`);
                    const monument = response.data.monument;
                    commit('SET_MONUMENT', monument);
                    return monument;
                } catch (error) {
                    console.error('Error fetching monument:', error);
                }
        },
        async fetchAllVoyages({ commit, state }) {
            // Otherwise, fetch from the API
            if (state.voyages.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}voyages`);
                    const voyages = response.data.voyages;
                    commit('SET_VOYAGES', voyages);
                    return voyages;
                } catch (error) {
                    console.error('Error fetching monument:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.voyages;
            }
        },
        async fetchAllGuides({ commit, state }) {
            // Otherwise, fetch from the API
            if (state.voyages.length === 0) {
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}voyages`);
                    const voyages = response.data.voyages;
                    commit('SET_VOYAGES', voyages);
                    return voyages;
                } catch (error) {
                    console.error('Error fetching monument:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.voyages;
            }
        },
        addReservation({ commit }, newReservation) {
            commit('SET_RESERVATION_HOTELS_CIRCUITS', newReservation);
        },
        addReservationRestaurant({ commit }, newReservation) {
            commit('SET_RESERVATION_RESTAURANTS_CIRCUITS', newReservation);
        },
        async fetchArticles({ commit, state }) {
            // Check if articles exist in localforage
            const cachedArticles = await localforage.getItem('articles');

            // If cached data exists and Vuex state is empty, use it
            if (cachedArticles && state.articles.length === 0) {
                commit('SET_ARTICLES', cachedArticles);
                return cachedArticles;
            }

            // Otherwise, fetch from the API
            if (state.articles.length === 0) {
                try {
                    const response = await axios.get('https://tunisiagotravel.com//blog/wp-json/wp/v2/posts');
                    const articles = response.data;

                    // Update Vuex state and store in localforage
                    commit('SET_ARTICLES', articles);
                    await localforage.setItem('articles', articles);

                    return articles;
                } catch (error) {
                    console.error('Error fetching articles:', error);
                    return [];
                }
            } else {
                // Return already-loaded Vuex state
                return state.articles;
            }
        },
        async fetchHotelsByDestinationId({ commit }, destinationid) {
            try {
                // Check if hotels for this destination are in the cache
                const cachedHotels = await localforage.getItem('hotels');

                if (cachedHotels) {
                    // If cached data exists, filter hotels for the given destination
                    const filteredList = cachedHotels.filter(hotel => hotel.destination.id === destinationid);
                    
                    // Commit the filtered list to the store
                    commit('SET_HOTELS_DESTINATIONS', filteredList);
                    
                    // Return the filtered list
                    return filteredList;
                } else {
                    // If no cached data, make an API request
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allhotels?destination_id=${destinationid}`);
                    const data = response.data.hotels;
                    // Commit the entire list to the store
                    commit('SET_HOTELS_DESTINATIONS', data);         
                    // Filter the data for the specific destination
                    const filteredList = data.filter(hotel => hotel.destination.id === destinationid);
                    
                    // Return the filtered list
                    return filteredList;
                }
            } catch (error) {
                console.error('Error fetching hotels:', error);
                return [];
            }
        },
          async fetchHotelByState({ commit },slug){
             try{
                const response= await axios.get(`${process.env.VUE_APP_BACKEND_URL}hotelsbystate/${slug}`);
                const data=response.data.hotels;
                commit('SET_HOTEL_BY_STATE',data);
                return data;
             }
             catch(error){
                console.error("Error fetching hotels ",error);
                return {}
             }
          },
          async fetchRestaurantByState({ commit },slug){
            try{
               const response= await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurantsbystate/${slug}`);
               const data=response.data.restaurants;
               commit('SET_RESTAURANT_BY_STATE',data);
               return data;
            }
            catch(error){
               console.error("Error fetching hotels ",error);
               return {}
            }
         },
         async fetchActivetyByState({ commit },slug){
            try{
               const response= await axios.get(`${process.env.VUE_APP_BACKEND_URL}activetybystate/${slug}`);
               const data=response.data.activety;
               commit('SET_ACTIVETY_BY_STATE',data);
               return data;
            }
            catch(error){
               console.error("Error fetching hotels ",error);
               return {}
            }
         },
         async fetchEventByState({ commit },slug){
            try{
               const response= await axios.get(`${process.env.VUE_APP_BACKEND_URL}eventsbystate/${slug}`);
               const data=response.data.events;
               commit('SET_EVENT_BY_STATE',data);
               return data;
            }
            catch(error){
               console.error("Error fetching hotels ",error);
               return {}
            }
         },
         // eslint-disable-next-line   
         async fetchHotelsByCityId({ commit }, city) {
            try {
                // Check if hotels are cached
                const cachedHotels = await localforage.getItem('hotels');

                if (cachedHotels) {
                    // If cached data exists, filter hotels for the given city
                    const filteredList = cachedHotels.filter(hotel => hotel.city_id === city);  
                    return filteredList;
                } else {
                    // If no cached data, make an API request
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allhotels`);
                    const data = response.data.hotels;
                    // Cache the full list of hotels for future use
                    await localforage.setItem('hotels', data);
                    // Filter the data for the specific city
                    const filteredList = data.filter(hotel => hotel.city_id === city);
                    
                    // Return the filtered list
                    return filteredList;
                }
            } catch (error) {
                console.error('Error fetching hotels by city:', error);
                return [];
            }
        },
          async fetchHotelsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}hotels?page=${page}`);
                const data = response.data.hotels;
                commit('SET_HOTELS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching hotels:', error);
                return [];
              }
          },
          async fetchMaisonByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}maison?page=${page}`);
                const data = response.data.maisons;
                commit('SET_MAISON_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching hotels:', error);
                return [];
              }
          },
          async fetchWeekendByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}weekend?page=${page}`);
                const data = response.data.events;
                commit('SET_WEEKEND_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchEventsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allevent?page=${page}`);
                const data = response.data.events;
                commit('SET_EVENTS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchActivityByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety?page=${page}`);
                const data = response.data.activety;
                commit('SET_ACTIVITY_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching events:', error);
                return [];
              }
          },
          async fetchCarByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allcars?page=${page}`);
                const data = response.data.cars;
                commit('SET_CAR_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching cars:', error);
                return [];
              }
          },
          async fetchMusseByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees?page=${page}`);
                const data = response.data.musees;
                commit('SET_MUSSE_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching musse:', error);
                return [];
              }
          },
          async fetchRestaurantsByPage({commit },page){
            try {
                const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurants?page=${page}`);
                const data = response.data.restaurants;
                commit('SET_RESTAURANTS_BY_PAGE', data); 
                return data;
              } catch (error) {
                console.error('Error fetching restaurants:', error);
                return [];
              }
          },
          async fetchEventById({ commit }, eventid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}event/${eventid}`);
              const event = response.data;
              commit('SET_EVENT', event); 
              return event;
            } catch (error) {
              console.error('Error fetching event:', error);
              return null;
            }
          },
          async fetchMusseById({ commit }, musseid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}musees/${musseid}`);
              const musse = response.data;
              commit('SET_MUSSE', musse); 
              return musse;
            } catch (error) {
              console.error('Error fetching musse:', error);
              return null;
            }
          },
          async fetchByMonumentId({ commit }, monumentid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}monument/${monumentid}`);
              const monument = response.data;
              commit('SET_MONUMEN', monument); 
              return monument;
            } catch (error) {
              console.error('Error fetching musse:', error);
              return null;
            }
          },
          async fetchByFestivalId({ commit }, festivalid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}festival/${festivalid}`);
              const festival = response.data;
              commit('SET_FESTIVAL', festival); 
              return festival;
            } catch (error) {
              console.error('Error fetching musse:', error);
              return null;
            }
          },
          async fetchRestaurantsByDestinationId({ commit }, destinationid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurants?destination_id=${destinationid}`);
              const data = response.data.restaurants.data;
              commit('SET_RESTAURANTS_DESTINATIONS', data); 
              return data;
            } catch (error) {
              console.error('Error fetching restaurants:', error);
              return [];
            }
          },
          async fetchRestaurantsByCityId({ commit }, city) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}restaurants?city_id=${city}`);
              const data = response.data.restaurants.data;
              commit('SET_RESTAURANTS_DESTINATIONS', data); 
              return data;
            } catch (error) {
              console.error('Error fetching restaurants:', error);
              return [];
            }
          },
          async fetchActivitiesByDestinationId({ commit }, cityid) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety?city_id=${cityid}`);
              const data = response.data.activety.data;
              let filteredList=data.filter(x=>x.city_id===cityid);
              commit('SET_ACTIVITIES_DESTINATIONS', filteredList); 
              return filteredList;
            } catch (error) {
              console.error('Error fetching activities:', error);
              return [];
            }
          },
          async fetchActivitiesByCityId({ commit }, city) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}allactivety?city_id=${city}`);
              const data = response.data.activety.data;
              let filteredList=data.filter(x=>x.city_id===city);
              commit('SET_ACTIVITIES_DESTINATIONS', filteredList); 
              return filteredList;
            } catch (error) {
              console.error('Error fetching activities:', error);
              return [];
            }
          },
          fetchSelectedHotel({ commit },hotel){
              commit("SET_SELECTED_HOTEL",hotel)
              localStorage.setItem("selectedHotel",hotel);
          },
          setUserData({commit},user){
            commit("SET_USER",user);
            localStorage.setItem('user',JSON.stringify(user));
          },
          setNameUser({commit},name){
             localStorage.setItem("name",name);
             commit("SET_NAME",name);
          },
          setImageUser({commit},image){
            localStorage.setItem("image",image);
            commit("SET_IMAGE",image);
         },
          setTokenData({commit},token){
             commit("SET_TOKEN",token);
             localStorage.setItem('token',token);
          },
          fetchSelectedCircuit({ commit },circuit){
            commit("SET_SELECTED_CIRCUIT",circuit)
            localStorage.setItem("selectedCircuit",circuit);
            },
          fetchSelectedRoom({ commit },room){
            commit("SET_SELECTED_ROOM",room);
            localStorage.setItem("selectedRoom",room);
         },
         fetchSelectedReservation({ commit },reservationSelected){
            commit("SET_SELECTED_RESERVATION",reservationSelected);
            localStorage.setItem("selectedReservation",reservationSelected);
         },
         fetchSelectedRestaurant({ commit },restaurantSelected){
            commit("SET_SELECTED_RESTAURANT",restaurantSelected);
            localStorage.setItem("restaurantSelected",restaurantSelected);
         },
         async fetchReservationHotels({ commit }, token) {
            console.log("my token ", token);
            if(this.state.reservationHotels.length===0){
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}dashbord/user/resarvationhotel`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    });
                    commit('SET_RESERVATION_HOTELS', response.data.reservation);
                    let data=response.data.reservation;
                    return data;
                  } catch (error) {
                    console.error('Error fetching hotel reservations:', error);
                    return [];
                  }
            }
            else{
                return this.state.reservationHotels;
            }

          },
          async fetchReservationRestaurants({ commit }, token) {
            if(this.state.reservationRestaurants.length===0){
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}dashbord/user/resarvationResterant`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    });
                    commit('SET_RESERVATION_RESTAURANTS', response.data.reservation);
                    return response.data.reservation;
                  } catch (error) {
                    console.error('Error fetching restaurants reservations:', error);
                    return [];
                  }
            }
            else{
                return this.state.reservationRestaurants;
            }

          },
          async fetchReservationCircuits({commit} ,token){
            if(this.state.reservationsCircuits.length===0){
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}dashbord/user/resarvationcircuit`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    });
                    commit('SET_RESERVATION_CIRCUITS', response.data.Circuit);
                    console.log("my circuit ",response.data.Circuit);
                    return response.data.Circuit;
                  } catch (error) {
                    console.error('Error fetching circuits reservations:', error);
                    return [];
                  }
            }
            else{
                return this.state.reservationRestaurants;
            }
          },
          async fetchReservationEvents({ commit }, token) {
            if(this.state.reservationEvents.length===0){
                try {
                    const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}dashbord/user/resarvationevents`, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    });
                    commit('SET_RESERVATION_EVENTS', response.data.reservation);
                    return response.data.reservation;
                  } catch (error) {
                    console.error('Error fetching events reservations:', error);
                    return [];
                  }
            }
            else{
                return this.state.reservationEvents;
            }
          },
          async fetchProfile({ commit }, token) {
            try {
              const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}dashbord/user/profil`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              commit('SET_PROFILE', response.data);
              return response.data;  
            } catch (error) {
              console.error('Error fetching events reservations:', error);
              return {}; 
            }
          },
    },
    getters:{
        currentLanguage(state) {
            return state.language;
        },
        getHotelSelected: (state) => state.hotelSelected? state.hotelSelected : localStorage.getItem("selectedHotel"),
        getRoomSelected:(state)=> state.roomSelected ? state.roomSelected : localStorage.getItem("selectedRoom"),
        getReservationSelected: (state)=>state.reservationSelected ? state.reservationSelected : localStorage.getItem("selectedReservation"),
        getRestaurantSelected: (state) => state.restaurantSelected ? state.restaurantSelected : localStorage.getItem("restaurantSelected"),
        getCircuitSelected: (state)=> state.circuitSelected ? state.circuitSelected : localStorage.getItem("selectedCircuit"),
        getConnectedUser: (state) => state.user? state.user : localStorage.getItem("user"),
        getTokenConnectedUser: (state) => state.token ? state.token : localStorage.getItem("token"),
        getNameUser: (state) => state.name ? state.name : localStorage.getItem("name"),
        getImageUser: (state) => state.image ? state.image : localStorage.getItem("image")

    }
});