<template>
    <div class="flex items-center p-4 bg-white border border-gray-200 rounded-2xl shadow-md space-x-4 hover:cursor-pointer">
      <!-- Image -->
      <img :src="medical.vignette" :alt="medical.name" class="w-16 h-16 rounded-lg object-cover"
            v-motion="{ initial: { opacity: 0, scale: 0.8 }, visible: { opacity: 1, scale: 1 } }"
      transition="{ delay: index * 0.2, duration: 0.8 }"
      />
    
      <!-- Content -->
      <div>
        <!-- Title -->
        <p class="text-md font-semibold text-gray-800 truncate-text" :title="medical.Name">
            <span v-if="currentLanguage==='fr'">{{ medical.name }}</span>
            <span v-if="currentLanguage==='ar'">{{ medical.name_ar }}</span>
            <span v-if="currentLanguage==='en'">{{ medical.name_en }}</span>
        </p>
        <!-- Location -->
        <div class="flex items-center text-sm text-gray-500">
          <i class="fas fa-map-marker-alt text-blue mr-2"></i>
          <span>
            <span v-if="currentLanguage==='fr'">{{ medical.address }}</span>
            <span v-if="currentLanguage==='ar'">{{ medical.address_ar }}</span>
            <span v-if="currentLanguage==='en'">{{ medical.address_en }}</span>
          </span>
        </div>
      </div>
    </div>
      </template>
  
  <script>
  export default {
    props: {
        medical: {
        type: Object,
        required: true
      }
    },
    watch: {
    "$i18n.locale"(newLocale) {
      this.currentLanguage = newLocale;
    },
  },
    data(){
      return{
        currentLanguage: this.$i18n.locale,
      }
    }
  };
  </script>
