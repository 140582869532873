<template>
    <div class="container-fluid h-full">
        <Map
                    :center="{ lat: 36.8663, lng: 10.187 }" 
                    :zoom="8"
                    class="w-full h-72 md:h-96"
                    >
                    <Marker
                            v-for="(element, index) in elements"
                            :key="index"
                            :position="{ lat: parseFloat(element.lat), lng: parseFloat(element.lng) }"
                            :title="element.name"
                            :icon="{ url: icon }"
                            @click="openInfoWindow(index)"
                        >
                        <InfoWindow
                v-if="infoWindowOpen === index"
                @closeclick="infoWindowOpen = null"
              >
                <div class="text-black">
                  <img
                    :alt="elements[index]['name']"
                    :src="elements[index]['cover']"
                    class="w-52 h-32 rounded-2xl"
                  />
                  <div class="text-blue font-bold" v-if="currentLanguage==='fr'">
                    {{ elements[index]["name"] }}
                  </div>
                  <div class="text-blue font-bold" v-if="currentLanguage==='ar'">
                    {{ elements[index]["name_ar"] }}
                  </div>
                  <div class="text-blue font-bold" v-if="currentLanguage==='en'">
                    {{ elements[index]["name_en"] }}
                  </div>
                </div>
              </InfoWindow>
                    </Marker>
        </Map>
    </div>
</template>
<script>
import { Map, Marker,InfoWindow } from '@fawmi/vue-google-maps';
 export default{
    components: {
        Map,
        Marker,
        InfoWindow
    },
    props: {
    elements: {
      type: Array,
      required: true,
    },
    icon: String
  },
  data(){
    return{
        infoWindowOpen: null,
        currentLanguage: this.$i18n.locale
    }
  },
  methods:{
    openInfoWindow(index) {
      this.infoWindowOpen = index;
    },
  },
  watch: {
    "$i18n.locale"(newLocale) {
      this.currentLanguage = newLocale;
    },
  },
 }
</script>